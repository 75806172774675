<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import '@qvant/qui-max/styles';


body {
    overflow: hidden; /* Hide scrollbars */
}
/* Global styles */
</style>
