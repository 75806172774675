import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "signup-container" }
const _hoisted_2 = { class: "button-group" }
const _hoisted_3 = {
  key: 0,
  class: "error-popup"
}
const _hoisted_4 = {
  key: 1,
  class: "splash-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form_item = _resolveComponent("q-form-item")!
  const _component_q_button = _resolveComponent("q-button")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h1", null, "Sign Up", -1)),
    _createVNode(_component_q_form, {
      ref: "form",
      model: $setup.formModel,
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_form_item, {
          label: "First Name",
          prop: "firstname"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: $setup.formModel.firstname,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formModel.firstname) = $event)),
              placeholder: "Enter your first name"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_form_item, {
          label: "Email",
          prop: "email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: $setup.formModel.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formModel.email) = $event)),
              type: "email",
              placeholder: "Enter your email"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_form_item, {
          label: "Password",
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: $setup.formModel.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formModel.password) = $event)),
              type: "password",
              placeholder: "Enter your password"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_form_item, {
          label: "Alina ID",
          prop: "alina_id"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: $setup.formModel.alina_id,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formModel.alina_id) = $event)),
              placeholder: "Enter your Alina ID"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_button, { onClick: $setup.handleSubmit }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Sign Up")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_q_button, {
            theme: "link",
            onClick: $setup.redirectToLogin
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Login")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    ($setup.isSignupError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString($setup.signupErrorMessage), 1),
          _createVNode(_component_q_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.isSignupError = false)),
            theme: "secondary"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Close")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    ($setup.showSplashScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[8] || (_cache[8] = [
          _createElementVNode("p", null, "Account created", -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}