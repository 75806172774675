import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "connexion-container" }
const _hoisted_2 = {
  key: 0,
  class: "error-popup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form_item = _resolveComponent("q-form-item")!
  const _component_q_button = _resolveComponent("q-button")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", null, [
      _createElementVNode("h1", null, "Connect to access Alina")
    ], -1)),
    _createVNode(_component_q_form, {
      ref: "form",
      model: _ctx.formModel,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_form_item, {
          label: "Email",
          prop: "email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: _ctx.formModel.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.email) = $event)),
              type: "text"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_form_item, {
          label: "Password",
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: _ctx.formModel.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.password) = $event)),
              type: "password"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_button, { onClick: _ctx.handleSubmitClick }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Login")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_q_button, {
          onClick: _ctx.redirectToSignUp,
          theme: "secondary"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Sign up")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    (_ctx.isLoginError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.loginErrorMessage), 1),
          _createVNode(_component_q_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoginError = false)),
            theme: "secondary"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Close")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}